import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×4\\@85% 1RM`}</p>
    <p>{`Wt’d Shoulder Bridge 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`10 Clean & Jerks (135/95)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`15-KBS’s (70/53)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      